import { useCookieConsent } from '@kaliber/cookie-consent'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConsentEvent } from '/machinery/tracking/cookieConsent'
import { useReportError } from '/machinery/ReportError'
import { useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'
import { HeadingMd } from '/features/buildingBlocks/Heading'

import styles from './CookiePermissions.css'

const translations = {
  functionalityStorage: {
    title: 'cookie-permission-title-functional',
  },
  analyticsStorage: {
    title: 'cookie-permission-title-analytics',
  },
  adStorage: {
    title: 'cookie-permission-title-advertising',
  },
}

export function CookiePermissions({ initialConsent, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const clientConfig = useClientConfig()
  const availablePermissions = clientConfig.cookieConsent.availablePermissions
  const reportError = useReportError()

  const {
    consent,
    updatePermission,
  } = useCookieConsent({
    availablePermissions,
    setCookieEndpoint: routeMap.api.v1.cookieConsent(),
    initialConsent,
    onChange: pushCookieConsent,
    onError: reportError
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <HeadingMd h={3}>Cookie settings</HeadingMd>
        </div>
        <div className={styles.permissions}>
          {availablePermissions.map(({ name, required }) => (
            <Permission
              key={name}
              disabled={required}
              value={required || consent?.permissions?.[name]  === 'granted'}
              onChange={value => { updatePermission(name, value) }}
              title={__`${translations[name].title}`}
            />
          ))}
        </div>
      </div>
      <div role='presentation' className={styles.background} />
    </div>
  )
}

function Permission({ title, value, onChange, disabled }) {
  return (
    <div className={cx(styles.componentPermission, disabled && styles.isDisabled, value && styles.isChecked)} key={value}>
      <label className={cx(styles.label, disabled && styles.isDisabled, value && styles.isChecked)}>
        <input
          type="checkbox"
          checked={value}
          onChange={() => onChange(!value)}
          className={styles.checkbox}
          {...{ disabled }}
        />
        <div className={styles.labelContent}>
          <strong className={styles.labeltitle}>
            {title}
          </strong>
        </div>
      </label>
    </div>
  )
}

function pushCookieConsent(consent) {
  pushToDataLayer(cookieConsentEvent(consent))
}
