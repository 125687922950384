import { QueryClientProvider, QueryClient } from 'react-query'
import { CookiePermissions } from './CookiePermissions'

const client = new QueryClient()

export default function CookiePermissionsUniversal({ initialConsent, layoutClassName = undefined }) {
  return (
    <QueryClientProvider {...{ client }}>
      <CookiePermissions {...{ initialConsent, layoutClassName }} />
    </QueryClientProvider>
  )
}
